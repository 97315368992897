import React from 'react'
import { Div, P, StyledLink } from '../styled-components'
import {
  addressOffice1, addressOffice2, addressOffice3
} from '../config'

const ContactInfo = () => (
  <Div
    padding="40px 10px"
    display="flex"
    flexDirection="column"
    align_items="center"
    background_color="#f2f2f2"
  >
    <Div
      width="345px"
      width960="275px"
      display="flex"
      flexDirection="column"
    >
      <P
        margin="0"
        fontSize="24px"
        fontWeight="bold"
        color="#666"
      >
        Premier Energy Solutions, Inc ({addressOffice1.officeName})
      </P>
      <P
        margin="0"
        fontSize="20px"
        color="#666"
      >
        {addressOffice1.address}
      </P>
      <P
        margin="0"
        fontSize="20px"
        color="#666"
      >
        {}
      </P>
      <P
        margin="0"
        fontSize="20px"
        color="#666"
      >
        Phone Number: {addressOffice1.phoneNumber}
      </P>
      {/* <P
        margin="0"
        fontSize="20px"
        color="#666"
      >
        Alternate Number: {}
      </P> */}
      <P
        margin="0"
        fontSize="20px"
        color="#666"
      >
        Email: {addressOffice1.email}
      </P>
    </Div>
    <Div
      margin="30px 0 0"
      width="345px"
      width960="275px"
      display="flex"
      flexDirection="column"
    >
      <P
        margin="0"
        fontSize="24px"
        fontWeight="bold"
        color="#666"
      >
        Premier Energy Solutions, Inc ({addressOffice2.officeName})
      </P>
      <P
        margin="0"
        fontSize="20px"
        color="#666"
      >
        {addressOffice2.address}
      </P>
      {/* <P
        margin="0"
        fontSize="20px"
        color="#666"
      >
        {}
      </P> */}
      <P
        margin="0"
        fontSize="20px"
        color="#666"
      >
        Phone Number: {addressOffice2.phoneNumber}
      </P>
      <P
        margin="0"
        fontSize="20px"
        color="#666"
      >
        Email: {addressOffice2.officeName}
      </P>
    </Div>
    <Div
      margin="30px 0 0"
      width="345px"
      width960="275px"
      display="flex"
      flexDirection="column"
    >
      <P
        margin="0"
        fontSize="24px"
        fontWeight="bold"
        color="#666"
      >
        Premier Energy Solutions, Inc ({addressOffice3.officeName})
      </P>
      <P
        margin="0"
        fontSize="20px"
        color="#666"
      >
        {addressOffice3.address}
      </P>
      {/* <P
        margin="0"
        fontSize="20px"
        color="#666"
      >
        {}
      </P> */}
      <P
        margin="0"
        fontSize="20px"
        color="#666"
      >
        Phone Number: {addressOffice3.phoneNumber}
      </P>
      <P
        margin="0"
        fontSize="20px"
        color="#666"
      >
        Email: {addressOffice3.email}
      </P>
    </Div>
  </Div>
)

export default ContactInfo