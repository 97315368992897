import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GeneralBanner from '../components/generalBanner'
import ContactInfo from '../components/contact/contactInfo'
import IframeAddress from '../components/contact/iframeAddress'
import Certification from '../components/certification'

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <GeneralBanner
      title="CONTACT"
    />
    <ContactInfo />
    <IframeAddress />
    <Certification
      background="#f2f2f2"
    />
  </Layout>
)

export default Contact