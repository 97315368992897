import React from 'react'

const IframeAddress = () => (
  <iframe
    title="Enterprise Adress"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.660466187049!2d-80.30218868497194!3d26.109999983479877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9083e0eb46b57%3A0x6e25772293c22ad0!2sFort+Lauderdale%2C+Florida+33355%2C+EE.+UU.!5e0!3m2!1ses-419!2spe!4v1528688020497"
    width="100%"
    height="400"
    style={{
      marginBottom:"0"
    }}
  />
)

export default IframeAddress